import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import styles from './WrapperChat.module.scss';

import HeaderChat from '../HeaderChat/HeaderChat';
import SendMessageChat from '../SendMessageChat/SendMessageChat';
import MessageChat from '../MessageChat/MessageChat';
import WrapperPrompts from '../WrapperPrompts/WrapperPrompts';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import TermsAndConditionsDialog from '../TermsAndConditionsDialog/TermsAndConditionsDialog';

import { chatService } from '../../features/chat/chatService';
import DisclaimerText from "../DisclaimerText/DisclaimerText";
import { localStorageService } from '../../features/localStorageService';
import { dataLayerService } from '../../features/dataLayerService';

function WrapperChat({prompts, threadUuid, onThreadUuidChange, onCancel}) {
    const [isShowConfirm, setIsShowConfirm] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isShowPrompt, setIsShowPrompt] = useState(true);
    const [isShowDisclaimer, setIsShowDisclaimer] = useState(false);
    const [isShowTermsAndCond, setIsShowTermsAndCond] = useState(true);
    const [pageTitle, setPageTitle] = useState('');
    const [parentHostname, setParentHostname] = useState('');
    const scrollRef = useRef(null);
    const ws = useRef(null);
    const connectionLostTimer = useRef(null);

    useEffect(() => {
        const fetch = async () => {
            setIsShowDisclaimer(true);
            const {uuid} = await chatService.getThreadUuid()
            onThreadUuidChange(uuid);
        }

        if (localStorageService.getItemWithExpiration('cwchat_show_terms_and_cond_exp') === true) {
            setIsShowTermsAndCond(true);
        }

        if (localStorageService.getItemWithExpiration('cwchat_show_terms_and_cond_exp') === false) {
            setIsShowTermsAndCond(false);
        }

        const params = new URL(document.location).searchParams;
        setParentHostname(params.get('parentHostname') || '');

        setInterval(() => {
            updatePageTitle();
        }, 2000);

        window.addEventListener('message', (event) => {
            if (event.data && event.data.title) {
                setPageTitle(event.data.title || '');
            }
        });

        fetch();
    }, []);

    useEffect(() => {
        const connect = async () => {
            if (ws.current) {
                ws.current.onclose = () => {};
                ws.current.close();
            }

            if (!threadUuid) {
                return;
            }

            ws.current = new WebSocket(process.env.REACT_APP_WS_URL + '?thread_uuid=' + threadUuid);

            ws.current.onopen = () => {
                setIsConnectedDebounce(true);
                console.info('WebSocket Connected');
            };

            ws.current.onmessage = (e) => {
                const message = JSON.parse(e.data);

                if (message.type === 'message') {
                    setIsLoading(false);
                    setMessages(messages => [
                        ...messages,
                        {
                            text: mutateMessage(message.message),
                            type: 'bot',
                            time: moment().format("MMM D, YYYY, hh:mm A")
                        }
                    ]);

                    dataLayerService.onChatEvent(
                        'message',
                        {
                            text: message.message,
                            chatMessageSender: 'bot',
                            time: moment().format("MMM D, YYYY, hh:mm A"),
                            chatThreadId: threadUuid
                        }
                    );
                }
            };

            ws.current.onclose = function(e) {
                setIsConnectedDebounce(false);
                console.log('Socket is closed. Reconnect will be attempted', e.reason);
                setTimeout(function() {
                    connect();
                }, 1);
            };

            ws.current.onerror = function(err) {
                console.error('Socket encountered error: ', err.message, 'Closing socket');
                ws.current.close();
                setIsLoading(false);
            };

            return () => {
                ws.current.close();
                setIsLoading(false);
            };
        }

        connect();
    }, [threadUuid]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    const updatePageTitle = () => {
        window.parent.postMessage({ action: 'getTitle' }, '*');
    }

    const sendNewMessage = async (value, isPrompt = false) => {
        if (!value) {
            return;
        }

        const messageData = { text: value, type: 'user', time: moment().format("MMM D, YYYY, hh:mm A"), pageTitle, parentHostname };

        setMessages([...messages, messageData]);
        setIsShowPrompt(false);
        setIsLoading(true);

        if (!isPrompt) {
            dataLayerService.onChatEvent('message', {
                text: value,
                chatMessageSender: 'user',
                time: moment().format("MMM D, YYYY, hh:mm A"),
                pageTitle,
                parentHostname,
                chatThreadId: threadUuid
            });
        }

        await chatService.sendMessage({message: value, pageTitle, parentHostname}, threadUuid);
    };

    const selectPrompt = ({prompt, heading, subheading, index}) => {
        if (!prompt || !isConnected) {
            return;
        }

        const messageData = {
            chatPresetTitle: heading,
            chatPresetSubText: subheading,
            chatPresetPosition: index + 1,
            chatThreadId: threadUuid
        };

        dataLayerService.onChatEvent('click_on_preset', messageData);

        sendNewMessage(prompt, true);
    }

    const showConfirmDialog = () => {
        setIsShowConfirm(true);
    }

    const hideConfirmDialog = () => {
        setIsShowConfirm(false);
    }

    const cancelChat = () => {
        onThreadUuidChange(null);
        setMessages([]);

        setIsShowConfirm(false);
        onCancel('closed');
    }

    const minimizedChat = () => {
        onCancel('minimized');
    }

    const setIsConnectedDebounce = (value) => {
        if (!value && connectionLostTimer.current) {
            return;
        }

        clearTimeout(connectionLostTimer.current);

        if (value) {
            setIsConnected(true);
            return;
        }

        connectionLostTimer.current = setTimeout(() => {
            setIsConnected(false);
        }, 6000);
    }

    const mutateMessage = (message) => {
        message = stripSourceText(message);
        message = insertAnalyticsInfoIntoLinks(message);
        return message;
    }

    const stripSourceText = (text) => {
        return text.replace(/【.*?】/g, '');
    }

    const insertAnalyticsInfoIntoLinks = (message) => {
        const params = new URL(document.location).searchParams;
        const parentHostname = params.get('parentHostname');

        let htmlString = `<div>${message}</div>`; // replace with your HTML string

        let parser = new DOMParser();
        let doc = parser.parseFromString(htmlString, 'text/html');

        let links = doc.querySelectorAll('a');

        links.forEach(link => {
            const url = new URL(link.href);
            const params = new URLSearchParams(url.search);
            const type = params.get('type');

            link.setAttribute('target', '_blank');
            link.setAttribute('data-parent-hostname', parentHostname || '');

            if (type) {
                link.setAttribute('data-link-type', type);
            }
        });

        let serializer = new XMLSerializer();
        return serializer.serializeToString(doc);
    }

    const approveTermsAndCond = () => {
        setIsShowTermsAndCond(false);
        localStorageService.setItemWithExpiration('cwchat_show_terms_and_cond_exp', false, 12);
    }

    return (
        <div className={styles.WrapperChat}>
            {isShowTermsAndCond
                ?
                    <TermsAndConditionsDialog
                        onCancel={cancelChat}
                        onApprove={approveTermsAndCond}
                    />
                : <>
                    { isShowConfirm &&
                        <ConfirmDialog
                            text='After closing the chat history will be lost, are you sure you want to close?'
                            onCancel={hideConfirmDialog}
                            onApprove={cancelChat}
                        />
                    }
                    <HeaderChat
                        text="Chat with our AI Wellness Coach"
                        isConnected={isConnected}
                        onMinimized={minimizedChat}
                        onCancel={showConfirmDialog}
                    />
                    <div className={styles.WrapperMessages}>
                        {isShowDisclaimer && <DisclaimerText />}

                        <MessageChat
                            text='What is stressing you out today? I might be able to help you.'
                            type='bot'
                        />
                        {messages.map((message, index) => (
                            <MessageChat
                                key={index}
                                text={message.text}
                                type={message.type}
                                time={message.time}
                            />
                        ))}
                        {isLoading && <div className={styles.loader}></div>}
                        <div ref={scrollRef} />
                    </div>
                    <SendMessageChat isConnected={isConnected} onNewMessage={sendNewMessage} />
                    {isShowPrompt && <WrapperPrompts prompts={prompts} onSelectPrompt={selectPrompt} />}
                </>
            }
        </div>
    );
}

export default WrapperChat;
