import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import OpenChatButton from './components/OpenChatButton/OpenChatButton';
import WrapperChat from './components/WrapperChat/WrapperChat';

import { chatService } from './features/chat/chatService';
import { dataLayerService } from './features/dataLayerService';

import './app.css';

function App() {
  const [showDialog, setShowDialog] = useState('closed'); // closed, minimized, open, reopen
  const [prompts, setPrompts] = useState([]);
  const [threadUuid, setThreadUuid] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const {presets, ChatInitialState, GoogleTagManagerCode} = await chatService.getConfig();
      setPrompts(presets);

      if (GoogleTagManagerCode) {
        dataLayerService.createAdditionalGMTScript(GoogleTagManagerCode);
      }

      if (ChatInitialState === 'opened') {
        openDialog();
      }
    }

    fetch();

    return () => {
      dataLayerService.onChatEvent('cancel', { chatThreadId: threadUuid });
    };
  }, []);

  useEffect(() => {
    if(threadUuid && showDialog === 'open') {
      dataLayerService.onChatEvent('open', { chatThreadId: threadUuid });
    }
  }, [threadUuid, showDialog]);

  const openDialog = () => {
    window.parent.postMessage({ action: 'openCwChatBot' }, '*');
    setShowDialog(showDialog === 'minimized' ? 'reopen' : 'open');
  };

  const cancelDialog = (mode = 'closed') => {
    window.parent.postMessage({ action: 'cancelCwChatBot' }, '*');
    setShowDialog(mode);

    if (mode === 'closed') {
      dataLayerService.onChatEvent('cancel', { chatThreadId: threadUuid });
    }
  }

  const chatClass = showDialog === 'minimized'
    ? 'minimized' : ['open', 'reopen'].includes(showDialog)
      ? 'open' : 'closed';

  return (
    <>
      <CSSTransition
        in={showDialog !== 'closed'}
        unmountOnExit={showDialog === 'closed'}
        timeout={0}
      >
        <div className={`chat-container ${chatClass}`}>
          <WrapperChat
            prompts={prompts}
            threadUuid={threadUuid}
            onThreadUuidChange={setThreadUuid}
            onCancel={cancelDialog}
          />
        </div>
      </CSSTransition>
      {(showDialog === 'closed' || showDialog === 'minimized') && (
        <OpenChatButton mode={showDialog} onClick={openDialog} />
      )}
    </>
  );
}

export default App;
